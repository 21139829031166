<template>
  <div class="iss-main-grid">
    <!-- <div class="flex pr-20 pb-20"> -->
    <a-tabs v-model:activeKey="activeKey">
      <!-- <a-tab-pane key="1" tab="微信群发" />
    <a-tab-pane key="2" tab="二维码" /> -->
      <a-tab-pane key="3" tab="模版消息" />
      <!-- <a-tab-pane key="4" tab="订阅通知" />
    <a-tab-pane key="5" tab="菜单消息" />
    <a-tab-pane key="6" tab="自动回复" />
    <a-tab-pane key="7" tab="自定义菜单" />
    <a-tab-pane key="8" tab="微信标签" /> -->
    </a-tabs>
    <div
      @click="skipUrl"
      class="fontStyle follow cursor-p"
      style="color: #333; text-decoration: underline"
    >
      关联公众号
    </div>
    <div>
      <template v-if="activeKey === '1'">
        <iss-pane-one></iss-pane-one>
      </template>
      <template v-else-if="activeKey === '2'">
        <iss-pane-two></iss-pane-two>
      </template>
      <template v-else-if="activeKey === '3'">
        <iss-pane-three></iss-pane-three>
      </template>
      <template v-else-if="activeKey === '4'">
        <iss-pane-four></iss-pane-four>
      </template>
      <template v-else-if="activeKey === '5'">
        <iss-pane-five></iss-pane-five>
      </template>
      <template v-else-if="activeKey === '6'">
        <iss-pane-six></iss-pane-six>
      </template>
      <template v-else-if="activeKey === '7'">
        <iss-pane-seven></iss-pane-seven>
      </template>
      <template v-else-if="activeKey === '8'">
        <iss-pane-eight></iss-pane-eight>
      </template>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Tabs } from 'ant-design-vue';
import IssPaneOne from './components/module/paneOne';
import IssPaneTwo from './components/module/paneTwo';
import IssPaneThree from './components/module/paneThree';
import IssPaneFour from './components/module/paneFour';
import IssPaneFive from './components/module/paneFive';
import IssPaneSix from './components/module/paneSix';
import IssPaneSeven from './components/module/paneSeven';
import IssPaneEight from './components/module/paneEight';
export default {
  name: 'module',
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    IssPaneOne,
    IssPaneTwo,
    IssPaneThree,
    IssPaneFour,
    IssPaneFive,
    IssPaneSix,
    IssPaneSeven,
    IssPaneEight,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const listRef = ref();
    const state = reactive({
      activeKey: '3',
    });
    return {
      listRef,
      ...toRefs(state),
      route,
      store,
      skipUrl() {
        router.push({
          path: '/system/enterprise',
          query: {
            key: 'channel',
          },
        });
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main-grid {
  background: #fff;
  position: relative;
  .follow {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
.fontStyle {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
:deep(.ant-tabs .ant-tabs-bar) {
  border: none;
}
</style>
